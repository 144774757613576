import React from "react";
import featureOne from "../assets/feature-1.svg";
import featreTwo from "../assets/feature-2.svg";
import featureThree from "../assets/feature-3.svg";
import featureFour from "../assets/feature-4.svg";

const FeatureCard = ({ title, description, image }) => (
  <div className="bg-[#4A5724] rounded-3xl overflow-hidden p-6 text-white">
    <h3 className="font-['Plus_Jakarta_Sans'] text-2xl font-semibold mb-2">
      {title}
    </h3>
    <p className="text-gray-200 mb-6 text-sm">{description}</p>
    <div className="rounded-2xl overflow-hidden">
      <img src={image} alt={title} className="w-full h-48 object-cover" />
    </div>
  </div>
);

const FeatureCards = () => {
  const features = [
    {
      title: "50+ Practical Projects & Mentor-led development",
      description:
        "Gain practical experience with real-world projects driven by mentor-led support",
      image: featureOne,
      type: "large",
    },
    {
      title: "Live Classes by CEOs/CXOs",
      description:
        "Gain exclusive insights and strategies from the world's top executive",
      image: featreTwo,
      type: "small",
    },
    {
      title: "360° Corporate Readiness",
      description:
        "Gain tech & inter-personal future skills that will help you outstand",
      image: featureThree,
      type: "small",
    },
    {
      title: "Learn Cutting-edge Tech & Compete Globally",
      description:
        "Gain exposure to cutting-edge technologies and innovation. Also rise up in platforms like LeetCode, CodeChef & more.",
      image: featureFour,
      type: "large",
    },
  ];

  return (
    <div className="container py-16 z-10 md:px-32 px-4 m-auto">
      <div className="grid md:grid-cols-5 grid-cols-1 md:grid-rows-2 grid-rows-1 md:gap-8 gap-4 w-full">
        {features.map((feature, index) => (
          <div
            className={`${
              feature.type === "large"
                ? "md:col-span-3 col-span-1"
                : "md:col-span-2 col-span-1"
            } row-span-1 bg-[#4A5724] rounded-3xl overflow-hidden text-white flex flex-col justify-between items-start`}
          >
            <div className="p-6">
              <h3 className="font-['Plus_Jakarta_Sans'] text-2xl font-semibold mb-2 text-[#F6F6DC]">
                {feature?.title}
              </h3>
              <p className="text-gray-200 text-base">{feature?.description}</p>
            </div>
            <div className="overflow-hidden w-full">
              <img
                src={feature?.image}
                alt={feature?.title}
                className={`${
                  feature.type === "large"
                    ? "md:ml-[132px] ml-0 md:w-[calc(100%-132px)] w-full md:rounded-tl-2xl rounded-none"
                    : "w-full border-0"
                } object-cover`}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeatureCards;
