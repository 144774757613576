import React from "react";

function Contact() {
  return (
    <div className="container py-16 flex justify-center items-center flex-col gap-10 md:px-32 px-4 m-auto">
      <h3 className="text-2xl font-medium">
        Get in touch with us to know more
      </h3>
      <button
        className=" text-[20px] font-normal leading-[24px] bg-[#F7B750] hover:bg-[#f6a62b] text-black px-8 py-3 rounded-2xl transition-colors"
        style={{
          textUnderlinePosition: "from-font",
          textDecorationSkipInk: "none",
        }}
      >
        Contact Us
      </button>
    </div>
  );
}

export default Contact;
