import React, { useState, useEffect, useRef } from "react";
import profilePic from "../assets/profile-pic.svg";
import ProfilePicTwo from "../assets/profilePic-2.svg";
import ProfilePicThree from "../assets/profilePic-3.svg";
import ProfilePicFour from "../assets/profilePic-4.svg";

const cards = [
  {
    name: "Alexis Rivera",
    role: "Software developer",
    pic: profilePic,
    quote: "The best decision I ever made! Now I'm working at Google!",
  },
  {
    name: "Jordan Matthews",
    role: "UI/UX Designer",
    pic: ProfilePicTwo,
    quote: "The experience was life-changing. I'm now designing for Apple!",
  },
  {
    name: "Taylor Wong",
    role: "Data Scientist",
    pic: ProfilePicThree,
    quote: "I built my dream career at Amazon, thanks to this platform.",
  },
  {
    name: "Morgan Carter",
    role: "Product Manager",
    pic: ProfilePicFour,
    quote: "The best decision I ever made! Now I'm working at Google!",
  },
  {
    name: "Jamie Lee",
    role: "DevOps Engineer",
    pic: ProfilePicThree,
    quote:
      "Highly recommended! I'm now a proud member of Facebook's tech team.",
  },
];
function Testimony() {
  const [translateX, setTranslateX] = useState(0);
  const scrollRef = useRef(null);

  useEffect(() => {
    const animate = () => {
      setTranslateX((prev) => {
        const newPosition = prev - 1;
        const cardWidth = 288; // w-72 = 18rem = 288px
        const resetPosition = -(cards.length * cardWidth);

        // If we've scrolled past the first set, reset to start of second set
        if (newPosition < resetPosition) {
          return resetPosition / 3;
        }
        return newPosition;
      });
    };

    const animationId = setInterval(animate, 20);
    return () => clearInterval(animationId);
  }, [cards.length]);

  const allCards = [...cards, ...cards, ...cards];

  return (
    <div className="w-full py-14">
      <h1 className="text-4xl font-bold mb-3 text-[#4B4F3A] text-center">
        What Our Students Say
      </h1>
      <div className="w-full overflow-hidden pt-14">
        <div
          ref={scrollRef}
          className="relative flex"
          style={{
            transform: `translateX(${translateX}px)`,
            transition: "transform 0.02s linear",
          }}
        >
          {allCards.map((card, index) => (
            <div key={index} className="w-72 flex-shrink-0 px-4">
              <div className="bg-[#6A6A3917] border rounded-lg  p-6 h-full transition-transform duration-300">
                <div className="w-full flex justify-start items-start gap-3">
                  <img
                    src={card?.pic}
                    className="w-12 h-12 border-0 rounded-full"
                    alt={card?.name}
                  />
                  <div className="flex flex-col justify-start items-start gap-1">
                    <h5 className="text-base text-[#28281B] font-bold">
                      {card.name}
                    </h5>
                    <p className="text-[#2B2B179E] font-normal">{card?.role}</p>
                  </div>
                </div>
                <div className="w-full flex justify-start items-start pl-6 mt-5">
                  <div className="flex">
                    <div className="border-l-2 border-l-[#6E6E4933] h-auto"></div>
                    <p className="text-sm text-[#28281B] pl-6">{card.quote}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Testimony;
