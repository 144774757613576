import bgImageOne from "../assets/bg-logo-one.svg";
import bgImageTwo from "../assets/bg-logo-two.svg";

const InstitutionalPartners = () => {
  const topPartners = [
    "/BITS.png",
    "/NIT.png",
    "/IIIT.png",
    "/NSUT.png",
    "/Sathyabama.png",
    "/TI.png",
    "/IITK.png",
    "/SR.png",
  ];

  const bottomPartners = [
    "/GITAM.png",
    "/KLU.png",
    "/MU.png",
    "/SIDDARTHA.png",
    "/VIGNAN.png",
  ];

  return (
    <div className="relative overflow-hidden my-16 md:px-32 px-4">
      <img src={bgImageOne} alt="" className="absolute left-0 top-0" />
      <img src={bgImageTwo} alt="" className="absolute right-0 bottom-0" />
      <div
        className="absolute inset-0 opacity-10"
        style={{
          backgroundImage: `url('/api/placeholder/1200/600')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />

      <div className="relative py-12">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-3 text-[#4B4F3A]">
            Institutional Partners
          </h1>
          <p className="text-gray-600 text-lg">
            We've supported over 800,000 individuals on their career journeys.
          </p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-6 mb-6 items-center justify-items-center md:px-40">
          {topPartners.map((partner, index) => (
            <div key={index} className="w-full flex justify-center">
              <img
                src={partner}
                alt={`Partner ${index + 1}`}
                className="max-w-[80px] h-auto object-contain"
              />
            </div>
          ))}
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 items-center justify-items-center md:px-40">
          {bottomPartners.map((partner, index) => (
            <div key={index} className="w-full flex justify-center">
              <img
                src={partner}
                alt={`Partner ${index + 1}`}
                className="max-w-[80px] h-auto object-contain"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InstitutionalPartners;
