import React, { useState, useEffect } from "react";
import { Menu, X } from "lucide-react";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClick = (e) => {
      if (isOpen && !e.target.closest("nav") && !e.target.closest("button")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, [isOpen]);

  // Prevent scroll when menu is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  return (
    <header className="fixed top-0 left-0 right-0 bg-white z-50">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <div className="flex-shrink-0">
            <a href="/" className="flex items-center">
              <img src="/logo.svg" alt="Logo" className="h-8 w-auto" />
            </a>
          </div>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center space-x-4">
            <a
              href="#"
              className="text-gray-800 hover:text-gray-600 px-3 py-2 rounded-md  font-normal transition-colors"
            >
              Home
            </a>
            <a
              href="#"
              className="bg-[#F7B750] hover:bg-[#f6a62b] text-black px-6 py-2 rounded-2xl  font-normal transition-colors"
            >
              Contact Us
            </a>
          </nav>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={(e) => {
                setIsOpen(!isOpen);
              }}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#F7B750] transition-colors"
              aria-expanded={isOpen}
            >
              <span className="sr-only">Open main menu</span>
              {isOpen ? (
                <X
                  className="block h-6 w-6"
                  aria-hidden="true"
                  pointerEvents="none"
                />
              ) : (
                <Menu
                  className="block h-6 w-6"
                  aria-hidden="true"
                  pointerEvents="none"
                />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div
        className={`${
          isOpen ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"
        } md:hidden fixed inset-0 z-40 transition-all duration-300 ease-in-out`}
      >
        <div
          className="fixed inset-0 bg-black bg-opacity-25"
          aria-hidden="true"
        />
        <nav className="fixed top-16 right-0 bottom-0 w-full max-w-sm bg-white shadow-xl transform transition-transform duration-300 ease-in-out">
          <div className="pt-5 pb-6 px-5 space-y-6">
            <div className="space-y-4">
              <a
                href="#"
                className="block text-gray-800 hover:bg-gray-50 px-3 py-2 rounded-md text-base font-medium transition-colors"
              >
                Home
              </a>
              <a
                href="#"
                className="block bg-[#F7B750] hover:bg-[#f6a62b] text-black px-6 py-2 rounded-full text-base font-medium text-center transition-colors"
              >
                Contact Us
              </a>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
