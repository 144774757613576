import { Shield, TrendingUp, Lightbulb, User } from "lucide-react";

const ImpactCard = ({ icon: Icon, title, description }) => (
  <div className="bg-[#61614217] rounded-2xl p-8 shadow-sm">
    <div className="flex items-center gap-4 mb-3">
      <Icon className="text-orange-500 h-6 w-6 flex-shrink-0" />
      <h2 className="text-[#4B4F3A] text-xl font-semibold">{title}</h2>
    </div>
    <p className="font-regular text-gray-600">{description}</p>
  </div>
);

const InstitutionalAchievements = () => {
  const achievements = [
    {
      icon: Shield,
      title: "Enhance Brand Value",
      description:
        "Produce graduates who are high-performing, ethical, and innovative.",
    },
    {
      icon: TrendingUp,
      title: "Increase Placement Rates",
      description:
        "Ensure trainees are industry-ready, resulting in higher hiring rates.",
    },
    {
      icon: Lightbulb,
      title: "Drive Innovation",
      description:
        "Graduates become contributors to research, development and thought leadership",
    },
    {
      icon: User,
      title: "Strengthen Industry Reputation",
      description:
        "Build a network of alumni who succeed and promote the company.",
    },
  ];

  return (
    <div className="w-full my-16 md:px-32 px-4">
      <h1 className="font-['Plus_Jakarta_Sans']  text-4xl font-bold text-center mb-12 text-black">
        Impact on Institutional Achievements
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {achievements.map((achievement) => (
          <ImpactCard
            key={achievement.title}
            icon={achievement.icon}
            title={achievement.title}
            description={achievement.description}
          />
        ))}
      </div>
    </div>
  );
};

export default InstitutionalAchievements;
