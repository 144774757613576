import React from "react";

const Hero = () => {
  return (
    <div className="relative min-h-screen">
      {/* Background Image with Overlay */}
      <div className="absolute inset-0 -z-40 w-full h-[150vh]">
        <img
          src="/HeroSubject.png"
          height="100%"
          alt="Students working"
          className="w-full h-full object-cover"
        />
        <div
          className="absolute inset-0 h-full"
          style={{
            background:
              "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 35%, rgba(255,255,255,0.8) 100%)",
          }}
        ></div>
      </div>

      {/* Content */}
      <div className="relative flex flex-col items-center justify-center min-h-screen px-4 sm:px-6 lg:px-8">
        {/* Logo */}
        <div className="mb-8">
          <img
            src="/skillgraph-logo.svg"
            alt="Skill Graph"
            className="h-16 md:h-28"
          />
        </div>

        {/* Text Content */}
        <div className="text-center max-w-4xl mx-auto">
          <h1
            className="font-['Plus_Jakarta_Sans'] text-[40px] md:text-[56px] font-bold md:leading-[80px] tracking-[-0.02em] text-center text-[#4A5724] mb-6"
            style={{
              textUnderlinePosition: "from-font",
              textDecorationSkipInk: "none",
            }}
          >
            Building India's Future Leaders In
            <br />
            Computer Science
          </h1>

          <p className="text-gray-700 text-lg md:text-xl mb-8">
            An On-Campus program for 8 semesters with Industry-Aligned
            Curriculum
          </p>

          {/* CTA Button */}
          <button
            className=" text-[20px] font-normal leading-[24px] bg-[#F7B750] hover:bg-[#f6a62b] text-black px-8 py-3 rounded-2xl transition-colors"
            style={{
              textUnderlinePosition: "from-font",
              textDecorationSkipInk: "none",
            }}
          >
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
