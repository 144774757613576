import React from "react";

const CURRICULUMS = [
  "Problem-Solving Mindset",
  "Technological Fluency",
  "Collaboration and Teamwork",
  "Ethical and Responsible Innovation",
  "Lifelong Learning Attitude",
  "Communication Skills",
  "Entrepreneurial Thinking",
  "Agile and Adaptable",
];
function Curriculum() {
  return (
    <div className="w-full text-center md:px-32 px-4">
      <h1 className="text-4xl font-bold mb-3 text-[#4B4F3A]">
        Curriculum Foundations
      </h1>
      <div className="w-full flex justify-center items-center">
        <div className="w-3/4 flex justify-center items-center gap-4 flex-wrap mt-14">
          {CURRICULUMS.map((item, index) => (
            <span
              key={index}
              className="whitespace-nowrap text-[#4D4D29] bg-[#F6F6DC] px-6 py-[10px] border-0 rounded-full"
            >
              {item}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Curriculum;
