import React from "react";
import fb from "../assets/facebook.svg";
import ig from "../assets/instagram.svg";
import tw from "../assets/twitter.svg";
import footerLogo from "../assets/footer-logo.svg";

const Links = [
  {
    heading: "Company",
    links: [
      { title: "About Us", link: "/" },
      { title: "Contact", link: "/" },
    ],
  },
  {
    heading: "Legal",
    links: [
      { title: "Privacy Policy", link: "/" },
      { title: "Terms of Service", link: "/" },
    ],
  },
];

const Socials = [
  {
    icon: fb,
  },
  {
    icon: ig,
  },
  {
    icon: tw,
  },
];

function Footer() {
  return (
    <div className="w-full bg-[#4D4D29] md:p-20 p-5 flex flex-col md:flex-row md:gap-0 gap-10 md:justify-between justify-start items-start">
      <img src={footerLogo} alt="logo" />
      {Links.map((item) => (
        <div className="flex flex-col gap-2">
          <h3 className="text-[#F6F6DC] text-base font-medium">
            {item?.heading}
          </h3>
          {item?.links?.map((link) => (
            <p className="text-[#F6F6DC9E]">{link?.title}</p>
          ))}
        </div>
      ))}
      <div className="flex flex-row justify-start items-center gap-4">
        {Socials?.map((item) => (
          <div className="">
            <img src={item?.icon} alt="social" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Footer;
