import Contact from "./components/Contact";
import CurriculumFoundations from "./components/CurriculamFoundations";
import Curriculum from "./components/Curriculum";
import FeatureCards from "./components/FeatureCards";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";
import InstitutionalAchievements from "./components/InstitutionalAchievements";
import InstitutionalPartners from "./components/InstitutionPartners";
import Testimony from "./components/Testimony";

function App() {
  return (
    <>
      <Header />
      <Hero />
      <FeatureCards />
      <InstitutionalAchievements />
      <InstitutionalPartners />
      <Curriculum />
      <Testimony />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
